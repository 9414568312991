<template>
  <el-button
    v-if="props.tableType === 'Accounts'"
    class="elv-accounts-header-main-right__import"
    link
    @click="onImportAccount"
  >
    <SvgIcon name="Import-document" width="16" height="16" />
    <span class="elv-accounts-header-main-right__import-text">{{ t('button.importAccount') }}</span>
  </el-button>

  <elvExportButton
    :params="props.exportParams"
    textColor="#636b75"
    :text="props.exportText"
    :noPermission="
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.entityAccount?.balanceExport
    "
  />

  <UploadCSVDialog
    ref="uploadCSVDialogRef"
    :title="t('title.importOnChainWalletAccount')"
    templateSlug="general-wallet-address"
    templateUrl="file/csv/Elven%20On-chain%20Account%20Template.csv"
    :noPermission="
      ['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.entityAccount?.create
    "
    :submitText="t('button.import')"
    @onSaveImport="onSaveImport"
    @onResetList="resetList"
  />
</template>

<script setup lang="ts">
import { $t } from '@/i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import { useEntityStore } from '@/stores/modules/entity'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'
import UploadCSVDialog from '@/pages/Financials/Project/components/UploadCSVDialog.vue'

type ExportParamsType = {
  type: string
  extra: Object
}

const props = defineProps({
  exportParams: {
    type: Object as () => ExportParamsType,
    default: () => {
      return {}
    }
  },
  tableType: {
    type: String,
    required: true
  },
  exportText: {
    type: String,
    default: $t('button.export')
  }
})

const { t } = useI18n()
const route = useRoute()
const uploadCSVDialogRef = useTemplateRef('uploadCSVDialogRef')

const entityStore = useEntityStore()

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onImportAccount = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  uploadCSVDialogRef.value?.onCheckUploadCSVDialog()
}

const onSaveImport = async (params: any) => {
  try {
    const data = {
      entityFileId: params.entityFileId
    }
    const res = await AccountsApi.importEntityAccount(entityId.value, data)
    uploadCSVDialogRef.value?.onSuccessImport()
    return Promise.resolve(res)
  } catch (error: any) {
    ElMessage.error(error.message)
    return Promise.reject(error)
  } finally {
    uploadCSVDialogRef.value?.onFinallyImport()
  }
}

const resetList = () => {
  emitter.emit('resetAccountList')
}
</script>

<style lang="scss" scoped>
.elv-accounts-header-main-right__import {
  position: relative;
  margin-right: 16px;
  color: #636b75;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    fill: #838d95;
  }

  span {
    margin-left: 8px;
  }

  &:hover {
    color: #1343bf;
    background-color: #fff;

    svg {
      fill: #1343bf;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    background: #dde1e6;
  }
}
</style>
